@import "@/styles/variables.scss";

.textJustify {
  text-align: justify;
}

.brief {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    &::first-letter {
      color: $active;
    }
  }
}

.brushes {
  margin-top: 30px;
}

.contacts {
  position: relative;
  clear: both;
  min-height: 258px;
  padding-bottom: 20px;
  background: url(~@/img/hand.png) no-repeat 17px 0;
  padding: 70px 0 0 615px;
}

.categories {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.block {
  display: flex;
  text-align: left;
  margin-right: 20px;

  ul {
    list-style-type: none;
    margin: 0 0 10px 25px;
    padding: 0;

    li {
      margin-bottom: 5px;

      a {
        color: $primaryLight;
        font-style: italic;
        text-transform: lowercase;
        text-decoration: underline;
        transition: all 0.25s;

        &:hover {
          color: $active;
          text-decoration: none;
        }
      }
    }
  }

  h2 {
    margin-top: 0;
    max-width: 220px;
    line-height: 1;

    &::first-letter {
      color: $active;
    }
  }
}
