@import "@/styles/variables.scss";

.footer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  border-top: 1px dashed #ababab;
  padding: 20px 0;
  color: $primary;
  margin: 62px auto 0 auto;

  .search {
    outline: none;
    padding: 3px 14px;
    margin-bottom: 0;
    border-radius: 15px;
    text-align: right;
    font-size: 12px;
    height: 14px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    transition: border linear .2s,box-shadow linear .2s;
  }
}

.details {
  text-align: left;
}

.share {
  display: flex;

  > * {
    margin-left: 10px;
  }
}
