@import "@/styles/variables.scss";

.quotes-container {
  width: 1100px;
  margin: 0 auto;
  position: relative;

  .quotes {
    position: absolute;
    width: 397px;
    height: 235px;
    top: -330px;
    right: 35px;
    z-index: 10;
    padding: 24px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .quotes-text {
    max-width: 330px;
    margin: 0 auto;
  }

  .swiper-container {
    margin-top: 20px;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    height: 135px;
  }

  .swiper-wrapper {
    color: #fff;
    line-height: 1;
    font-size: $fs-normal;
    font-style: italic;
    text-shadow: #000 2px 2px 2px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: #fff;

    &::after {
      font-size: $fs-large;
    }
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev {
    left: 0;
  }

  h2 {
    color: #fff;
    font-size: 35px;
    font-weight: normal;
    margin: 0;
    line-height: 28px;

    b {
      color: $active;
    }
  }

  strong {
    font-size: 56px;
    display: block;
    margin-top: 30px;
    text-transform: uppercase;
  }
}
