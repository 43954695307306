@import "@/styles/variables.scss";

.container {
  display: flex;
  align-items: center;

  .menuItem {
    margin-right: 20px;
  }
}

.menu {
  margin-bottom: 62px;

  :global .btn {
    border: none;
  }

  .title {
    display: block;
    color: #00000080;
    font-size: 16px;
    font-weight: bold;
    margin: 15px 0;
  }

  .submenu {
    transition: all 3s linear;
    display: none;
  }

  .submenu.active {
    display: block;
    transition: all 5s linear;
  }

  .active .title {
    color: #fff;
    text-decoration: none;
    background-color: $active;
    box-shadow: inset 0 3px 8px rgba(0,0,0,0.5);
    border-radius: 20px;
    padding: 10px 18px;
    margin: 15px 0;
  }

  .item {
    width: 210px;
    margin-bottom: 8px;
    margin-left: 22px;
    border-bottom: 1px dashed #CCC;
  }

  .item a {
    font-style: normal;
    font-weight: normal;
    color: $primaryLight;
    text-decoration: none;
    font-size: $fs-active;
    transition: color 0.3s;

    &:hover {
      color: $active;
    }
  }

  .inlineItem {
    display: inline-block;
    margin: 8px 15px;
    color: #666;
    font-style: italic;
    text-transform: lowercase;

    &:hover,
    &.active {
      color: #c00;
    }
  }
}
