.image-swiper {
  .swiper-container {
    padding-bottom: 15px;
  }

  .swiper-slide-active {
    position: relative;
  }

  .swiper-slide-active:before,
  .swiper-slide-active:after {
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 15px;
    left: 10px;
    width: 50%;
    top: 80%;
    max-width:300px;
    background: #777;
    box-shadow: 0 15px 10px #777;
    transform: rotate(-3deg);
  }

  .swiper-slide-active:after {
    transform: rotate(3deg);
    right: 10px;
    left: auto;
  }

  .swiper-slide-next,
  .swiper-slide-prev {
    background-color: #fff;
    opacity: 0.4;
    transition: all 0.3s;

    &:hover {
      opacity: 0.8;
    }
  }
}