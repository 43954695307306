@import "@/styles/variables.scss";

.serviceBlock {
  display: flex;
  align-items: flex-start;

  .content {
    max-width: 70%;
    margin-left: 2%;

    h1 {
      margin-bottom: 30px;
    }

    h3 {
      margin-top: 50px;
      font-size: $fs-large;
    }

    p {
      margin-bottom: 24px;
    }

    .blockList {
      margin-bottom: 48px;
    }

    .image {
      overflow: hidden;
    }
  }
}