@import "@/styles/variables.scss";

.container {
  display: flex;
  justify-content: space-between;

  .block {
    width: 50%;
    padding-left: 20px;
  }

  h3 {
    margin: 10px 0 30px 0;
  }
}

.form {
  width: 600px;

  label {
    font-size: $fs-less-normal;
    margin-bottom: 5px;
    line-height: 28px;
  }

  input {
    height: 20px;
    line-height: 20px;
    width: 210px;
  }

  input, textarea {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    transition: border linear .2s,box-shadow linear .2s;
    display: inline-block;
    padding: 4px 6px;
    margin-bottom: 9px;
    font-size: 14px;
    color: #555;
    border-radius: 3px;

    &:focus {
      border-color: rgba(82,168,236,0.8);
      outline: 0;
    }
  }

  textarea {
    max-width: 450px;
  }

  .btn {
    display: inline-block;
    padding: 4px 14px;
    margin-bottom: 0;
    font-size: $fs-less-normal;
    line-height: 20px;
    color: #333;
    text-align: center;
    text-shadow: 0 1px 1px rgba(255,255,255,0.75);
    vertical-align: middle;
    cursor: pointer;
    background-color: #f5f5f5;
    background-image: linear-gradient(to bottom,#fff,#e6e6e6);
    background-repeat: repeat-x;
    border: 1px solid #bbb;
    border-color: #e6e6e6 #e6e6e6 #bfbfbf;
    border-bottom-color: #a2a2a2;
    border-radius: 4px;
    outline: none;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.2), 0 1px 2px rgba(0,0,0,0.05);

    &:hover {
      color: #333;
      text-decoration: none;
      background-color: #e6e6e6;
      background-position: 0 -15px;
      transition: background-position .1s linear;
    }

    &:active {
      background-color: #e6e6e6;
      background-image: none;
      outline: 0;
      box-shadow: inset 0 2px 4px rgba(0,0,0,0.15), 0 1px 2px rgba(0,0,0,0.05);
    }
  }
}

.phones {
  p {
    font-family: Arial,Helvetica,sans-serif;
    font-size: 36px;
    font-weight: bold;
    line-height: 36px;
    margin:0 0 20px 0;
    color: #000;
  }
}

.block {
  .details {
    margin: 54px 0 24px 0;

    a {
      color: $active;
    }
  }
}

.response {
  margin: 15px 0;
}

.errorResponse,
.invalidResponse {
  color: #f00;
}

.sentResponse {
  color: rgb(4, 136, 4);
}
