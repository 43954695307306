.searchInput {
  display: flex;

  input {
    border-radius: 14px 0 0 14px;
    padding: 5px 14px;
    border: 1px solid #ccc;
    background-color: #fff;
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    width: 530px;
    color: #555;

    &:focus {
      outline: none;
      border-color: rgba(82,168,236,0.8);
    }
  }

  :global .btn {
    border-radius: 0 14px 14px 0;
    background-color: #f5f5f5;
    background-image: linear-gradient(to bottom,#fff,#e6e6e6);
    background-repeat: repeat-x;
    border: 1px solid #ccc;
    border-left: 0;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.2), 0 1px 2px rgba(0,0,0,0.05);
    color: #333;
  }
}

.searchResults {
  margin-top: 50px;

  h4 a {
    color: #000;

    &:hover {
      text-decoration: none;
    }
  }
}
